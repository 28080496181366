body {
    background: #f6f8fc;
}

.cabecera {
    padding: 1rem 0px;
    background: #00A1C7;
    color: #fff;
    span {
        color: #fff;
        &.Mui-completed{
            color: #fff;
            font-weight: bold;
    
        }
    }
}

.horarios {
    width: 100%;
    height: 456px;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 5px;
        background: #f6f8fc;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #00A1C7;
        border: 1px solid #00A1C7;
    }
    .chip-active {
        background: #00A1C7;
        color: white;
    }
}

.numTitle{
    padding: 0.5rem 0.25rem;
    margin-bottom: 0.5rem;
    background: #fff;
    border-radius: 10px;
    font-weight: 600;

    span{
        border: 2px solid #00A1C7;
        background: #fff;
        border-radius: 50px;
        height: 27px;
        width: 27px;
        padding: 0px 8px;
        display: inline-block;
    }
}

.background-white {
    background: #fff;
}
.text-color-1 {
   color: #00A1C7;
}
.text-color-2 {
    color: #60769A;
}

.btn-radius{
    border-radius: 25px!important;
}
.text-small{
    font-size: 0.9rem!important;
}